@keyframes spinner-line-fade-more {
  0%, 100% {
    opacity: 0; /* minimum opacity */
  }
  1% {
    opacity: 1;
  }
}

@keyframes spinner-line-fade-quick {
  0%, 39%, 100% {
    opacity: 0.25; /* minimum opacity */
  }
  40% {
    opacity: 1;
  }
}

@keyframes spinner-line-fade-default {
  0%, 100% {
    opacity: 0.22; /* minimum opacity */
  }
  1% {
    opacity: 1;
  }
}

@keyframes spinner-line-shrink {
  0%, 25%, 100% {
    /* minimum scale and opacity */
    transform: scale(0.5);
    opacity: 0.25;
  }
  26% {
    transform: scale(1);
    opacity: 1;
  }
}

.miew-canvas > canvas {
  display: block;
  width: 100%;
  height: 100%;
}

.miew-container,
.miew-canvas {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.miew-message {
  /* trick for centered error-reporting*/
  background-color: #ccc;
  display: table;
  width: 100%;
  height: 100%;
}

.miew-message p {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: large;
  }

.mode-message {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 4px;
  color: silver;
  background-color: rgba(64, 64, 64, 0.75);
  display: flex;
  align-items: left;
  justify-content: left;
  opacity: 0;
}

.atom-info {
  margin: 10px;
  text-align: left;
}

.overlay {
  pointer-events: none;
}
